var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("transition", { attrs: { name: "animation" } }, [
    _vm.isShow
      ? _c(
          "div",
          {
            staticClass: "dialogBox",
            class: { isShowMask: _vm.mask == true },
            attrs: { id: "lxj-dialog" },
            on: {
              touchmove: function ($event) {
                $event.preventDefault()
              },
              click: _vm.clickMaskCloseFn,
            },
          },
          [
            _c(
              "div",
              {
                staticClass: "dialogBoxContent flexc",
                style: { height: _vm.height, width: _vm.width },
                on: {
                  click: function ($event) {
                    $event.stopPropagation()
                  },
                },
              },
              [
                _c("div", { staticClass: "flexr acenter jsb hearers" }, [
                  _c(
                    "div",
                    { staticClass: "title" },
                    [_vm._v(_vm._s(_vm.title)), _vm._t("header")],
                    2
                  ),
                  _vm._v(" "),
                  _c("i", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.showCloseIcon,
                        expression: "showCloseIcon",
                      },
                    ],
                    staticClass: "el-icon-close",
                    staticStyle: { cursor: "pointer" },
                    on: { click: _vm.close },
                  }),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "bodybody" }, [_vm._t("default")], 2),
                _vm._v(" "),
                _vm.$slots.footer
                  ? _c(
                      "div",
                      { staticClass: "footfoot" },
                      [_vm._t("footer")],
                      2
                    )
                  : _vm._e(),
              ]
            ),
          ]
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }