import { getToken } from "@/utils/auth";
var baseUrl = process.env.VUE_APP_BASE_API;
// 文件读取公共路径
export var baseFilePath = baseUrl + '/files/';
// 文件上传公共路径
export var uploadFilePath = baseUrl + '/upload';
// 文件下载公共路径
export var downloadFilePath = baseUrl + '/download/';
// excel下载
export var downloadExcelPath = baseUrl + '/downloadExcel/';
// 上传图片token
export var myToken = function myToken() {
  var headers = {
    'Authorization': getToken()
  };
  return headers;
};