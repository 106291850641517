module.exports = {
  title: '小厨娘卡券平台',
  /**
   * @type {boolean} true | false
   * @description 是否显示设置右侧面板
   */
  showSettings: false,
  /**
   * @type {boolean} true | false
   * @description 是否显示页面标签
   */
  tagsView: false,
  /**
   * @type {boolean} true | false
   * @description 固定头部
   */
  fixedHeader: true,
  /**
   * @type {boolean} true | false
   * @description 是否在侧边栏显示Logo
   */
  sidebarLogo: true,
  /**
   * @type {string | array} 'production' | ['production', 'development']
   * @description Need show err logs component.
   * The default is only used in the production env
   * If you want to also use it in dev, you can pass ['production', 'development']
   */
  errorLog: 'production',
  /**
   * token头
   */
  tokenHead: 'Bearer ',
  /**
   * 认证请求头
   */
  authorization: 'Authorization'
};