import "core-js/modules/es6.object.keys";
import "core-js/modules/web.dom.iterable";
import "/var/lib/jenkins/workspace/xcn-web-uat/node_modules/_core-js@2.6.12@core-js/modules/es6.array.iterator.js";
import "/var/lib/jenkins/workspace/xcn-web-uat/node_modules/_core-js@2.6.12@core-js/modules/es6.promise.js";
import "/var/lib/jenkins/workspace/xcn-web-uat/node_modules/_core-js@2.6.12@core-js/modules/es6.object.assign.js";
import "/var/lib/jenkins/workspace/xcn-web-uat/node_modules/_core-js@2.6.12@core-js/modules/es7.promise.finally.js";
import Vue from 'vue';
import Cookies from 'js-cookie';
import 'normalize.css/normalize.css'; // a modern alternative to CSS resets

import Element from 'element-ui';
import "./styles/element-variables.scss";
import '@/styles/index.scss'; // global css

import "./utils/qktemp"; // 引入自定义组件

import App from "./App";
import store from "./store";
import router from "./router";
import "./icons"; // icon
import "./permission"; // permission control
import "./utils/error-log"; // error log

import * as filters from "./filters"; // global filters

Vue.use(Element, {
  size: Cookies.get('size') || 'medium' // set element-ui default size
});

// register global utility filters
Object.keys(filters).forEach(function (key) {
  Vue.filter(key, filters[key]);
});
Vue.config.productionTip = false;
new Vue({
  el: '#app',
  router: router,
  store: store,
  render: function render(h) {
    return h(App);
  }
});