import Cookies from 'js-cookie';
var TokenKey = 'Admin-Token';
var RefreshTokenKey = 'Admin-Refresh-Token';
export function getToken() {
  return Cookies.get(TokenKey);
}
export function setToken(token) {
  return Cookies.set(TokenKey, token);
}
export function getRefreshToken() {
  return Cookies.get(RefreshTokenKey);
}
export function setRefreshToken(refreshToken) {
  return Cookies.set(RefreshTokenKey, refreshToken);
}
export function removeToken() {
  return Cookies.remove(TokenKey);
}
export function removeRefreshToken() {
  return Cookies.remove(RefreshTokenKey);
}