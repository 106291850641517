import request from '@/utils/request';
// 用户名密码登录
export function loginByUsername(data) {
  return request({
    url: '/sys/loginByUsername',
    method: 'post',
    data: data
  });
}
// 获取用户菜单导航
export function getUserNav() {
  return request({
    url: '/sys/menu/nav',
    method: 'get'
  });
}
// 获取当前登录用户信息
export function queryUserInfo() {
  return request({
    url: '/sys/user/info',
    method: 'get'
  });
}
// 用户退出
export function logout() {
  return request({
    url: '/system/logout',
    method: 'post'
  });
}
export function getCodeImg() {
  return request({
    url: '/captcha',
    method: 'get'
  });
}

// 刷新token
export function refreshTokenApi(data) {
  return request({
    url: '/refreshToken',
    method: 'get',
    params: data
  });
}