import Vue from 'vue';
import Router from 'vue-router';
Vue.use(Router);

/* Layout */
import Layout from '@/layout';

/* Router Modules */

/**
 * constantRoutes
 * a base page that does not have permission requirements
 * all roles can be accessed
 */
export var constantRoutes = [{
  path: '/redirect',
  component: Layout,
  hidden: true,
  children: [{
    path: '/redirect/:path(.*)',
    component: function component() {
      return import('@/views/redirect/index');
    }
  }]
}, {
  path: '/login',
  component: function component() {
    return import('@/views/login/index');
  },
  hidden: true
}, {
  path: '/error-page',
  component: Layout,
  hidden: true,
  children: [{
    path: '404',
    component: function component() {
      return import('@/views/error-page/404');
    }
  }, {
    path: '/401',
    component: function component() {
      return import('@/views/error-page/401');
    }
  }]
}, {
  path: '/',
  component: Layout,
  redirect: '/home',
  children: [{
    path: 'home',
    component: function component() {
      return import('@/views/home/index');
    },
    name: 'Home',
    meta: {
      title: '首页',
      icon: 'dashboard',
      affix: true
    }
  }, {
    path: 'addOrUpdateCouppon',
    hidden: true,
    component: function component() {
      return import('@/views/couponManagement/coupon/addOrUpdateCouppon');
    },
    name: 'AddOrUpdateCouppon',
    meta: {
      title: '券模板新增/编辑',
      icon: 'people'
    }
  }, {
    path: 'addOrUpdateGiftCard',
    hidden: true,
    component: function component() {
      return import('@/views/physicalGiftCard/giftCardTemplate/addOrUpdateGiftCard');
    },
    name: 'addOrUpdateGiftCard',
    meta: {
      title: '实体礼品卡新增/编辑',
      icon: 'people'
    }
  }, {
    path: 'addOrUpdateWxCoupon',
    hidden: true,
    component: function component() {
      return import('@/views/card-data/channel/wechat/addOrUpdateWxCoupon');
    },
    name: 'addOrUpdateWxCoupon',
    meta: {
      title: '微信券新增/编辑',
      icon: 'people'
    }
  }, {
    path: 'verifyManagement',
    hidden: true,
    component: function component() {
      return import('@/views/shopManagement/verification/verifyManagement');
    },
    name: 'VerifyManagement',
    meta: {
      title: '核销员管理',
      icon: 'people'
    }
  },
  // 卡券数据
  {
    path: 'channe-order/channe-order-detail',
    component: function component() {
      return import('@/views/card-data/channe-order/channe-order-detail');
    },
    name: 'channe-order-detail',
    hidden: true,
    meta: {
      title: '渠道订单详情',
      icon: 'dashboard'
      // affix: true
    }
  }, {
    path: 'card/card-detail',
    component: function component() {
      return import('@/views/card-data/card/card-detail');
    },
    name: 'card-detail',
    hidden: true,
    meta: {
      title: '卡券详情',
      icon: 'people'
      // affix: true
    }
  },
  // 新建活动
  {
    path: 'h5-coupon-redemption-add',
    component: function component() {
      return import('@/views/card-data/send-coupon/h5-coupon-redemption-add');
    },
    name: 'h5-coupon-redemption-add',
    hidden: true,
    meta: {
      title: '新建/编辑发券活动',
      icon: 'dashboard'
      // affix: true
    }
  }, {
    path: 'sim-send-coupon/sim-coupon-redemption-add',
    component: function component() {
      return import('@/views/card-data/sim-send-coupon/sim-coupon-redemption-add');
    },
    name: 'sim-coupon-redemption-add',
    hidden: true,
    meta: {
      title: '新建/编辑短信发券',
      icon: 'dashboard'
      // affix: true
    }
  }, {
    path: 'data-statistical/detail-list',
    component: function component() {
      return import('@/views/card-data/data-statistical/detail-list');
    },
    name: 'data-statistical-detail-list',
    hidden: true,
    meta: {
      title: '数据明细',
      icon: 'dashboard'
      // affix: true
    }
  },
  // 抖音团购券
  {
    path: '/channel/douyin/creat',
    component: function component() {
      return import('@/views/card-data/channel/douyin/creat');
    },
    name: 'channel-douyin-creat',
    hidden: true,
    meta: {
      title: '新建/编辑抖音团购券',
      icon: 'people'
    }
  },
  // 口碑团购券
  {
    path: '/channel/koubei/addOrUpdateMerchantCoupon',
    component: function component() {
      return import('@/views/card-data/channel/koubei/addOrUpdateMerchantCoupon');
    },
    name: 'addOrUpdateMerchantCoupon',
    hidden: true,
    meta: {
      title: '新建/编辑口碑券',
      icon: 'people'
    }
  },
  // 美团
  {
    path: '/channel/meituan/addOrUpdateMerchantMTCoupon',
    component: function component() {
      return import('@/views/card-data/channel/meituan/addOrUpdateMerchantMTCoupon');
    },
    name: 'addOrUpdateMerchantMTCoupon',
    hidden: true,
    meta: {
      title: '新建/编辑美团券',
      icon: 'people'
    }
  },
  // 招行
  {
    path: '/channel/merchantsBank/addOrUpdateMerchantCMBCoupon',
    component: function component() {
      return import('@/views/card-data/channel/merchantsBank/addOrUpdateMerchantCMBCoupon');
    },
    name: 'addOrUpdateMerchantCMBCoupon',
    hidden: true,
    meta: {
      title: '新建/编辑招商银行券',
      icon: 'people'
    }
  }, {
    path: 'stockChangeRecord',
    component: function component() {
      return import('@/views/couponManagement/coupon/stockChangeRecord');
    },
    name: 'stockChangeRecord',
    hidden: true,
    meta: {
      title: '库存修改记录',
      icon: 'people'
    }
  }, {
    path: 'changePriceRecord',
    component: function component() {
      return import('@/views/physicalGiftCard/giftCardTemplate/changePriceRecord');
    },
    name: 'changePriceRecord',
    hidden: true,
    meta: {
      title: '售价修改记录',
      icon: 'people'
    }
  }, {
    path: '/v2/generate-statistics/generate-detail',
    component: function component() {
      return import('@/views/card-data/v2/generate-statistics/generate-detail');
    },
    name: 'v2-generate-detail',
    hidden: true,
    meta: {
      title: '生成详情',
      icon: 'dashboard'
    }
  }, {
    path: '/v2/validation-statistics/validation-detail',
    component: function component() {
      return import('@/views/card-data/v2/validation-statistics/validation-detail');
    },
    name: 'v2-validation-detail',
    hidden: true,
    meta: {
      title: '验证详情',
      icon: 'dashboard'
    }
  }, {
    path: 'makeCard',
    hidden: true,
    component: function component() {
      return import('@/views/physicalGiftCard/makeCardList/makeCard');
    },
    name: 'makeCard',
    meta: {
      title: '批量制卡',
      icon: 'people'
    }
  }, {
    path: 'allocationDetail',
    hidden: true,
    component: function component() {
      return import('@/views/physicalGiftCard/makeCardAllocation/allocationDetail');
    },
    name: 'allocationDetail',
    meta: {
      title: '纸质券调拨详情',
      icon: 'people'
    }
  }, {
    path: 'cardIntoShop',
    hidden: true,
    component: function component() {
      return import('@/views/physicalGiftCard/makeCardAllocation/cardIntoShop');
    },
    name: 'cardIntoShop',
    meta: {
      title: '纸质券入库',
      icon: 'people'
    }
  }, {
    path: 'cardAllocation',
    hidden: true,
    component: function component() {
      return import('@/views/physicalGiftCard/makeCardAllocation/cardAllocation');
    },
    name: 'cardAllocation',
    meta: {
      title: '纸质券调拨',
      icon: 'people'
    }
  }, {
    path: 'addSaleOrder',
    hidden: true,
    component: function component() {
      return import('@/views/physicalGiftCard/saleOrder/addSaleOrder');
    },
    name: 'addSaleOrder',
    meta: {
      title: '纸质券销售',
      icon: 'people'
    }
  }, {
    path: 'saleOrderDetail',
    hidden: true,
    component: function component() {
      return import('@/views/physicalGiftCard/saleOrder/saleOrderDetail');
    },
    name: 'saleOrderDetail',
    meta: {
      title: '纸质券销售详情',
      icon: 'people'
    }
  }, {
    path: 'deliveryDetail',
    hidden: true,
    component: function component() {
      return import('@/views/physicalGiftCard/exchangeSendOrder/deliveryDetail');
    },
    name: 'deliveryDetail',
    meta: {
      title: '纸质券兑换配送详情',
      icon: 'people'
    }
  }, {
    path: 'deliveryRecord',
    hidden: true,
    component: function component() {
      return import('@/views/physicalGiftCard/deliveryRecord/index');
    },
    name: 'DeliveryRecord',
    meta: {
      title: '发货记录',
      icon: 'people'
    }
  }, {
    path: 'exchangeSendOrderEdit',
    hidden: true,
    component: function component() {
      return import('@/views/physicalGiftCard/exchangeSendOrder/edit');
    },
    name: 'Edit',
    meta: {
      title: '配送订单编辑',
      icon: 'people'
    }
  }, {
    path: 'exchangePickUpOrderEdit',
    hidden: true,
    component: function component() {
      return import('@/views/physicalGiftCard/pickUpOrder/edit');
    },
    name: 'OrderEdit',
    meta: {
      title: '预约订单编辑',
      icon: 'people'
    }
  }, {
    path: 'orderDetail',
    hidden: true,
    component: function component() {
      return import('@/views/physicalGiftCard/pickUpOrder/orderDetail');
    },
    name: 'orderDetail',
    meta: {
      title: '纸质券预约自提详情',
      icon: 'people'
    }
  }, {
    path: 'billDetail',
    hidden: true,
    component: function component() {
      return import('@/views/physicalGiftCard/pickUpBill/billDetail');
    },
    name: 'billDetail',
    meta: {
      title: '预约自提明细',
      icon: 'people'
    }
  }

  // {
  // 	path: 'saleOrderRecord',
  // 	hidden: true,
  // 	component: () => import('@/views/physicalGiftCard/saleOrder/index'),
  // 	name: 'saleOrderRecord',
  // 	meta: {
  // 		title: '销售订单记录',
  // 		icon: 'people'
  // 	}
  // },
  ]
},
// {
// 	path: '/channel',
// 	component: Layout,
// 	hidden: false,
// 	meta: {
// 		title: '渠道卡券',
// 		icon: 'dashboard',
// 		// affix: true
// 	},
// 	children: [
// 		{
// 			path: '/channel/douyin/coupon-list',
// 			component: () => import('@/views/card-data/channel/douyin/coupon-list'),
// 			name: 'channel-douyin-list',
// 			meta: {
// 				title: '抖音团购券',
// 				icon: 'people'
// 			}
// 		},
// 		{
// 			path: '/channel/meituan/list',
// 			component: () => import('@/views/card-data/channel/meituan/list'),
// 			name: 'channel-meituan-list',
// 			meta: {
// 				title: '美团券',
// 				icon: 'people'
// 			}
// 		},
// 	]
// },

// {
// 	path: '/card-data',
// 	component: Layout,
// 	hidden: false,
// 	meta: {
// 		title: '卡券数据',
// 		icon: 'dashboard',
// 		affix: true
// 	},
// 	children: [{
// 			path: 'card',
// 			component: () => import('@/views/card-data/card/index'),
// 			name: 'card',
// 			meta: {
// 				title: '券查询',
// 				icon: 'dashboard'
// 				// affix: true
// 			}
// 		},

// 		{
// 			path: 'channe-order/channe-order-list',
// 			component: () => import('@/views/card-data/channe-order/index'),
// 			name: 'channe-order-list',
// 			meta: {
// 				title: '渠道订单明细',
// 				icon: 'dashboard'
// 				// affix: true
// 			}
// 		},
// 		{
// 			path: 'channe-order/channe-order-statistical',
// 			component: () => import('@/views/card-data/channe-order/channe-order-statistical'),
// 			name: 'channe-order-statistical',
// 			meta: {
// 				title: '渠道订单统计',
// 				icon: 'dashboard'
// 				// affix: true
// 			}
// 		},

// 		{
// 			path: 'data-statistical/data-statistical-list',
// 			component: () => import('@/views/card-data/data-statistical/index'),
// 			name: 'data-statistical-list',
// 			meta: {
// 				title: '数据统计',
// 				icon: 'dashboard'
// 				// affix: true
// 			}
// 		},

// 	]
// },

// {
// 	path: '/card-data',
// 	component: Layout,
// 	meta: {
// 	    title: '数据中心',
// 	    icon: 'dashboard',
// 	},
// 	children:[
// 		{
// 		    path: '/v2/generate-statistics/index',
// 		    component: () => import('@/views/card-data/v2/generate-statistics/index'),
// 		    name: 'generate-statistics-index',
// 		    meta: {
// 		        title: '生成统计',
// 		        icon: 'dashboard',
// 		    }
// 		},

// 		{
// 		    path: '/v2/validation-statistics/index',
// 		    component: () => import('@/views/card-data/v2/validation-statistics/index'),
// 		    name: 'validation-statistics-index',
// 		    meta: {
// 		        title: '验证统计',
// 		        icon: 'dashboard',
// 		    }
// 		},

// 		{
// 		    path: '/v2/generate-statistics/generate-list',
// 		    component: () => import('@/views/card-data/v2/generate-statistics/generate-list'),
// 		    name: 'generate-list-index',
// 		    meta: {
// 		        title: '生成明细',
// 		        icon: 'dashboard',
// 		    }
// 		},
// 		{
// 		    path: '/v2/validation-statistics/validation-list',
// 		    component: () => import('@/views/card-data/v2/validation-statistics/validation-list'),
// 		    name: 'v2-validation-list-index',
// 		    meta: {
// 		        title: '验证明细',
// 		        icon: 'dashboard',
// 		    }
// 		},

// 		{
// 		    path: '/v2/generate-statistics/generate-detail',
// 		    component: () => import('@/views/card-data/v2/generate-statistics/generate-detail'),
// 		    name: 'v2-generate-detail',
// 			hidden: true,
// 		    meta: {
// 		        title: '生成详情',
// 		        icon: 'dashboard',
// 		    }
// 		},
// 		{
// 		    path: '/v2/validation-statistics/validation-detail',
// 		    component: () => import('@/views/card-data/v2/validation-statistics/validation-detail'),
// 		    name: 'v2-validation-detail',
// 			hidden: true,
// 		    meta: {
// 		        title: '验证详情',
// 		        icon: 'dashboard',
// 		    }
// 		},

// 	]
// },
{
  path: '/people',
  component: Layout,
  hidden: true,
  children: [{
    path: 'center',
    component: function component() {
      return import('@/views/home/userCenter');
    },
    name: 'userCenter',
    meta: {
      title: '个人中心',
      icon: 'people'
    }
  }]
}
// {
//   path: '/download',
//   component: Layout,
//   hidden: false,
//   children: [{
//     path: 'center',
//     component: () => import('@/views/download/index'),
//     name: 'download-list',
//     meta: {
//       title: '下载中心',
//       icon: 'people'
//     }
//   }]
// }
];

export default new Router({
  // mode: 'history', // require service support
  scrollBehavior: function scrollBehavior() {
    return {
      y: 0
    };
  },
  routes: constantRoutes
});