import { constantRoutes } from '@/router';
import Layout from '@/layout';
var permission = {
  state: {
    routers: constantRoutes,
    addRouters: []
  },
  mutations: {
    SET_ROUTERS: function SET_ROUTERS(state, routers) {
      state.addRouters = routers;
      state.routers = constantRoutes.concat(routers);
    }
  },
  actions: {
    generateRoutes: function generateRoutes(_ref, asyncRouter) {
      var commit = _ref.commit;
      commit('SET_ROUTERS', asyncRouter);
    }
  }
};
/**
 * 添加动态(菜单)路由
 */
export var filterAsyncRouter = function filterAsyncRouter(menuList) {
  var routers = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : [];
  menuList.filter(function (router) {
    var route = {
      path: router.url,
      component: null,
      name: '',
      meta: {
        menuId: router.menuId,
        title: router.menuName,
        icon: router.icon
      }
    };
    // Layout组件特殊处理
    if (router.menuType === 0) {
      route['component'] = Layout;
    } else {
      route['name'] = router.menuName;
      route['component'] = function (resolve) {
        return require(["@/views/".concat(router.component)], resolve);
      };
    }
    if (router.children && router.children.length > 0) {
      route['children'] = filterAsyncRouter(router.children);
    }
    routers.push(route);
  });
  return routers;
};
export default permission;