// utils - constants 常量

export var Constants = {
  // 卡券状态
  ORDER_STATUS: {
    initial: '待付款',
    audited: '待发货',
    dispatching: '待收货',
    completed: '已完成',
    canceled: '已取消'
  },
  // 卡券状态
  GOODS_STATUS: {
    up: '上架',
    down: '下架'
  },
  //  优惠券类型
  COUPON_TYPES_ARR: ['全部', '代金券', '折扣券', '提领券'
  // '满减券',
  // '换购券'
  ],

  //  优惠券类型
  SEND_TYPES_ARR: ['全部', 'H5', '短信', '渠道发券'],
  //  优惠券类型v2
  SEND_TYPES_ARR_v2: ['全部', 'H5', '短信'],
  SEND_QD_ARR: ['全部', '抖音', '口碑', '美团', '招行'],
  //  颜色选择器
  PICKER_COLOR: ['#3AA1FF', '#13C2C2', '#FBD437', '#F2637B', '#975FE5', '#4ECB73', '#F759AB', '#BAE637', '#597EF7', '#FF7A45'],
  // 时间选择器
  PICKER_OPTION: {
    // 快捷键
    shortcuts: [{
      text: '最近一周',
      onClick: function onClick(picker) {
        var end = new Date();
        var start = new Date();
        start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
        picker.$emit('pick', [start, end]);
      }
    }, {
      text: '最近一个月',
      onClick: function onClick(picker) {
        var end = new Date();
        var start = new Date();
        start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
        picker.$emit('pick', [start, end]);
      }
    }, {
      text: '最近三个月',
      onClick: function onClick(picker) {
        var end = new Date();
        var start = new Date();
        start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
        picker.$emit('pick', [start, end]);
      }
    }],
    // 禁用当前日期之前的日期
    disabledDate: function disabledDate(date) {
      //disabledDate 文档上：设置禁用状态，参数为当前日期，要求返回 Boolean
      return date.getTime() < Date.now() - 24 * 60 * 60 * 1000;
    }
  },
  // 时间选择器
  PICKER_OPTION_1: {
    // 快捷键
    shortcuts: [{
      text: '最近一周',
      onClick: function onClick(picker) {
        var end = new Date();
        var start = new Date();
        start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
        picker.$emit('pick', [start, end]);
      }
    }, {
      text: '最近一个月',
      onClick: function onClick(picker) {
        var end = new Date();
        var start = new Date();
        start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
        picker.$emit('pick', [start, end]);
      }
    }, {
      text: '最近三个月',
      onClick: function onClick(picker) {
        var end = new Date();
        var start = new Date();
        start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
        picker.$emit('pick', [start, end]);
      }
    }]
  },
  // col设置
  COL_SETTING: {
    span: 12,
    xs: 12,
    sm: 8,
    md: 8,
    lg: 6,
    xl: 6
  }
};