import "core-js/modules/es6.array.find";
import "core-js/modules/es7.array.includes";
import "core-js/modules/web.dom.iterable";
import _toConsumableArray from "/var/lib/jenkins/workspace/xcn-web-uat/node_modules/_@babel_runtime-corejs2@7.21.0@@babel/runtime-corejs2/helpers/esm/toConsumableArray.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Pagination from '@/components/Pagination';
export default {
  name: 'ISelectActivity1',
  components: {
    Pagination: Pagination
  },
  filters: {
    statusType: function statusType(val) {
      switch (val) {
        case 1:
          return '代金券';
        case 2:
          return '折扣券';
        case 3:
          return '提领券';
      }
    }
  },
  // props: ['hasArr', 'title','deptCode','ishow'],
  props: {
    ishow: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: '选择优惠券'
    },
    hasArr: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    // showLable:{
    // 	type:String,
    // 	default:'1'
    // },
    shopCode: {
      type: String,
      default: ''
    }
  },
  data: function data() {
    return {
      dialogVisible: false,
      chosenArr: [],
      dialogTable1: [],
      dialogTable2: [],
      dialogTable3: [],
      total: 0,
      total4: 0,
      total5: 0,
      limit: 10,
      page: 1,
      loading: false,
      searchName: '',
      goodsName: 1,
      tabsActive: '1',
      goodsArr: [{
        value: 1,
        label: '商品名称'
      }, {
        value: 3,
        label: 'SPU码'
      }],
      classifyId: '',
      classArr: [{
        value: '1',
        label: '优惠券活动'
      }, {
        value: '2',
        label: '直播活动'
      }, {
        value: '3',
        label: '自定义活动'
      }],
      // shopCode: '',
      shopArr: []
    };
  },
  watch: {
    ishow: function ishow(val) {
      // console.log(val)
      this.dialogVisible = val; // 新增isVisible的watch，监听变更并同步到IsShowPage上
      if (this.dialogVisible) {
        // this.tabsActive = this.showLable
        // this.getshopList();
        // this.gogetList();
      }
    }
  },
  methods: {
    gogetList: function gogetList() {
      var that = this;
      that.page = 1;
      that.limit = 10;
      switch (this.tabsActive) {
        case '1':
          that.getList1();
          break;
        case '2':
          that.getList2();
          break;
        case '3':
          that.getList3();
          break;
        case '4':
          that.getList4();
          break;
        case '5':
          that.getList5();
          break;
        default:
          break;
      }
    },
    getshopList: function getshopList() {
      var _this = this;
      return new Promise(function (resolve, reject) {
        var data = {
          page: _this.page,
          limit: 1000,
          state: 1
        };
        _this.$getCommon(_this.$codesKey.QK_SYS_SHOP_LIST_100000035, data).then(function (res) {
          if (res.status == 0) {
            if (res.data.records == null) {
              return _this.$message({
                message: '可选择店铺为空，请先设置权限'
              });
            }
            _this.shopArr = res.data.records;
            if (!_this.shopCode) {
              _this.shopCode = _this.shopArr[0].shopCode;
            }
            _this.getList1();
            resolve(_this.shopArr);
          } else {
            _this.$message.error(res.message);
            reject();
          }
        });
      });
    },
    // 优惠券列表
    getList1: function getList1() {
      var _this2 = this;
      var data = {
        page: this.page,
        limit: this.limit,
        // shopCode: this.shopCode,
        activityType: 1 // 优惠券
        // activityName: this.searchName
      };
      // if (this.searchTime) {
      // 	data.startTime = this.searchTime[0];
      // 	data.endTime = this.searchTime[1];
      // }
      if (this.shopCode) {
        data.shopCode = this.shopCode;
      }
      if (this.searchName) {
        data.activityName = this.searchName;
      }
      this.$getCommon(this.$codesKey.QKACTIVITY00001, data).then(function (res) {
        if (res.status == 0) {
          _this2.dialogTable1 = res.data.records;
          _this2.total = res.data.total;
        } else {
          _this2.$message.error(res.message);
        }
      });
    },
    // 直播列表
    getList2: function getList2() {
      var _this3 = this;
      var data = {
        page: this.page,
        limit: this.limit
        // shopCode: this.shopCode,
      };
      // if (this.searchTime) {
      // 	data.startTime = this.searchTime[0];
      // 	data.endTime = this.searchTime[1];
      // }
      // if (this.classifyId != 0) {
      // 	data.liveStatus = this.classifyId;
      // }
      if (this.shopCode) data.shopCode = this.shopCode;
      if (this.searchName) data.name = this.searchName;
      this.$getCommon(this.$codesKey.QKACTIVITY00007, data).then(function (res) {
        if (res.status == 0) {
          _this3.dialogTable2 = res.data.records;
          _this3.total = res.data.total;
        } else {
          _this3.$message.error(res.message);
        }
      });
    },
    // 自定义列表
    getList3: function getList3() {
      var _this4 = this;
      var obj = {
        page: this.page,
        limit: this.limit
      };
      if (this.searchName) obj.activityName = this.searchName;
      if (this.shopCode) obj.shopCode = this.shopCode;
      this.$getCommon(this.$codesKey.QK_ACTIVITY_PAGE_200000002, obj).then(function (res) {
        if (res.status == 0) {
          _this4.dialogTable3 = res.data.records;
          _this4.total = res.data.total;
        } else {
          _this4.$message.error(res.message);
        }
      });
    },
    // 多选事件
    handleSelectionChange: function handleSelectionChange(val) {
      this.chosenArr = val;
    },
    getActivitys: function getActivitys() {
      var _this5 = this;
      var tempHasArr = this.hasArr || [];
      var arr = [].concat(_toConsumableArray(tempHasArr), _toConsumableArray(this.chosenArr));
      var flag = [];
      var newArr = [];
      arr.forEach(function (item) {
        if (_this5.tabsActive == 1) {
          if (!flag.includes(item.activityCode)) {
            flag.push(item.activityCode);
            newArr.push(item);
          }
        } else if (_this5.tabsActive == 2) {
          if (!flag.includes(item.activityCode)) {
            flag.push(item.activityCode);
            newArr.push(item);
          }
        } else if (_this5.tabsActive == 3) {
          if (!flag.includes(item.activityId)) {
            flag.push(item.activityId);
            newArr.push(item);
          }
        }
      });
      var d = {
        type: this.tabsActive,
        arr: newArr
      };
      this.$emit('cb', d);
      this.closes();

      // switch (n){
      // 	case 1:
      // 		var obj={
      // 			activityImg:row.activityImg,
      // 			activityName:row.activityName,
      // 			activityDoc:row.shareDescription,
      // 			resourceId:row.activityCode,
      // 			resourceType:2
      // 		}

      // 		break;
      // 	case 2:
      // 		var obj={
      // 			activityImg:row.coverImg,
      // 			activityName:row.name,
      // 			activityDoc:row.name,
      // 			resourceId:row.activityCode,
      // 			resourceType:2
      // 		}
      // 		break;
      // 	case 3:
      // 		var obj={
      // 			activityImg:'',
      // 			activityName:row.activityName,
      // 			activityDoc:row.activityName,
      // 			resourceId:row.activityId,
      // 			resourceType:2
      // 		}

      // 		break;
      // 	default:
      // 		break;
      // }

      // var newArr = [];
      // newArr.push(obj);
      // this.$emit('cb', newArr);
      // this.dialogVisible = false;
    },
    toggle: function toggle(rows, flag) {
      var _this6 = this;
      rows = rows || [];
      rows.forEach(function (element) {
        // 将传入的数组和当前页数组匹配
        var finder = _this6.dialogTable.find(function (val) {
          return val.productCode == element.productCode;
        });
        if (finder) {
          _this6.$refs.mytable.toggleRowSelection(finder, flag);
        }
      });
    },
    tabClick: function tabClick(tab, event) {
      this.gogetList();
    },
    clearAll: function clearAll() {
      this.gogetList();
    },
    beforeClose: function beforeClose(flag) {
      this.dialogVisible = flag;
      this.closes();
    },
    closes: function closes() {
      this.dialogTable1 = [];
      this.dialogTable2 = [];
      this.dialogTable3 = [];
      this.$emit('update:ishow', false);
      this.dialogVisible = false;
    }
  }
};