exports = module.exports = require("../../node_modules/_css-loader@1.0.1@css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "\n", ""]);

// exports
exports.locals = {
	"menuText": "#fff",
	"menuActiveText": "#fff",
	"subMenuActiveText": "#fff",
	"menuBg": "#2b303a",
	"menuHover": "#1890ff",
	"subMenuBg": "#2b303a",
	"subMenuHover": "#1890ff",
	"sideBarWidth": "210px"
};