import { render, staticRenderFns } from "./index_v2.vue?vue&type=template&id=5bf22d61&scoped=true&"
import script from "./index_v2.vue?vue&type=script&lang=js&"
export * from "./index_v2.vue?vue&type=script&lang=js&"
import style0 from "./index_v2.vue?vue&type=style&index=0&id=5bf22d61&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/_vue-loader@15.10.1@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5bf22d61",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/var/lib/jenkins/workspace/xcn-web-uat/node_modules/_vue-hot-reload-api@2.3.4@vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('5bf22d61')) {
      api.createRecord('5bf22d61', component.options)
    } else {
      api.reload('5bf22d61', component.options)
    }
    module.hot.accept("./index_v2.vue?vue&type=template&id=5bf22d61&scoped=true&", function () {
      api.rerender('5bf22d61', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/qkChooseCoupon/index_v2.vue"
export default component.exports