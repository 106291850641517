import Vue from 'vue';
import { Constants } from '@/utils/constants.js';
console.log(Constants);
Vue.prototype.$Constants = Constants;
Vue.prototype.$tiemOption = Constants.PICKER_OPTION;
Vue.prototype.$colors = Constants.PICKER_COLOR;
import intf from '@/api/interface.js';
Vue.prototype.$apiV2 = intf;
// 引入指令
// import '@/directive/index'

// 弹窗
import qkdialog from "../components/qkDialog/qkDialog.vue";

// 选择优惠券
import qkChooseCoupon from "../components/qkChooseCoupon/index.vue";
// 选择优惠券
import qkChooseCouponV2 from "../components/qkChooseCoupon/index_v2.vue";

// 选择优惠券
import qkChooseCouponSim from "../components/qkChooseCoupon/index_sim.vue";

// 选择并导入用户
import qkAddUser from "../components/qkAddUser/index.vue";
import qkAddUserV2 from "../components/qkAddUser/index_v2.vue";

// 封装按钮
import throatButton from '@/components/throatBtn.vue';

// vue防抖和节流
Vue.component('qk-t-Button', throatButton);
Vue.component('qk-dialog', qkdialog);
Vue.component('qk-choose-coupon-dialog', qkChooseCoupon);
Vue.component('qk-choose-coupon-dialog-v2', qkChooseCouponV2);
Vue.component('qk-choose-coupon-dialog-sim', qkChooseCouponSim);
Vue.component('qk-add-user', qkAddUser);
Vue.component('qk-add-user-v2', qkAddUserV2);