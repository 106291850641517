//
//
//

export default {
  name: 'ThroatBtn',
  props: {
    size: {
      type: String,
      default: 'center'
    },
    type: {
      type: String,
      default: 'primary'
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data: function data() {
    return {
      timer: null
    };
  },
  computed: {
    evet: function evet() {
      if (this.$listeners.click) {
        this.$listeners.click = this.throat('click');
      }
      return this.$listeners;
    } // disabled(){
    //     if(this.timer){
    //         return true;
    //     }else{
    //         return false;
    //     }
    // },
  },
  methods: {
    throat: function throat(method) {
      // const me = this;
      // return (...args)=>{
      //     if(!me.timer){
      //         me.$emit(method,...args);
      //         me.timer = setTimeout(() => {
      //             me.timer = null;
      //         }, me.$attrs.throat || 5000); //默认5s的，节流
      //     }else{
      //         me.$emit("droped",...args); //点击太频繁的事件提示
      //     }
      // }

      var me = this; // 防抖
      var timer = null;
      return function () {
        for (var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++) {
          args[_key] = arguments[_key];
        }
        clearTimeout(timer);
        timer = setTimeout(function () {
          me.$emit.apply(me, [method].concat(args));
        }, me.$attrs.throat || 1000);
      };
    }
  }
};