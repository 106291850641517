var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "box-v2" }, [
    _vm.dataForm.couponName
      ? _c(
          "div",
          { staticClass: "couponItem" },
          [
            _c("couponCard", {
              staticStyle: { background: "#fff" },
              attrs: { obj: _vm.dataForm },
            }),
            _vm._v(" "),
            _c("div", { staticClass: "statusBox" }, [
              _c("div", { staticClass: "statusName1" }, [_vm._v("使用规则：")]),
              _vm._v(" "),
              _c("div", { staticClass: "statusName" }, [
                _vm._v(_vm._s(_vm.dataForm.useRule)),
              ]),
            ]),
            _vm._v(" "),
            _vm.dataForm.cpmCouponTemplateFixedTerm
              ? _c("div", { staticClass: "statusBox" }, [
                  _c("div", { staticClass: "statusName1" }, [
                    _vm._v("时间规则："),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "statusName" }, [
                    _c("span", { staticClass: "timePre" }, [
                      _vm._v(
                        _vm._s(
                          _vm._f("timeFilter")(
                            _vm.dataForm.cpmCouponTemplateFixedTerm
                          )
                        )
                      ),
                    ]),
                  ]),
                ])
              : _vm._e(),
          ],
          1
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }