import request from '@/utils/request';

// h5发券活动list
export function stock_h5_list_interface(data) {
  return request({
    url: '/cpm/merchant/coupon/stock-h5/page',
    method: 'post',
    data: data
  });
}

// h5新增发券活动
export function stock_h5_add_interface(data) {
  return request({
    url: '/cpm/merchant/coupon/stock-h5/add',
    method: 'post',
    data: data
  });
}

// h5查询权活动信息
export function stock_h5_info_interface(query) {
  return request({
    url: '/cpm/merchant/coupon/stock-h5/info',
    method: 'get',
    params: query
  });
}

// h5活动修改
export function stock_h5_update_interface(data) {
  return request({
    url: '/cpm/merchant/coupon/stock-h5/update',
    method: 'put',
    data: data
  });
}

// h5发券用户管理
export function coupon_stockUser_list_interface(data) {
  return request({
    url: '/cpm/merchant/coupon/stockUser/page',
    method: 'post',
    data: data
  });
}

// h5发券用户 新增
export function coupon_stockUser_add_interface(data) {
  return request({
    url: '/cpm/merchant/coupon/stockUser/add',
    method: 'post',
    data: data
  });
}

// h5发券用户 删除
export function coupon_stockUser_delete_interface(data) {
  return request({
    url: '/cpm/merchant/coupon/stockUser/delete',
    method: 'post',
    data: data
  });
}

// 选择卡券列表
export function coupon_pageCouponStockAddCouponTemplate_list_interface(data) {
  return request({
    url: '/cpm/merchant/coupon/template/pageCouponStockAddCouponTemplate',
    method: 'post',
    data: data
  });
}

// 短信重发
export function coupon_resendMsg_interface(query) {
  return request({
    url: '/cpm/customer/coupon/resendMsg',
    method: 'get',
    params: query
  });
}

// 短信重发,sim发券详情
export function coupon_stock_sms_resendMsg_interface(query) {
  return request({
    url: '/cpm/merchant/coupon/stock-sms/info/resendMsg',
    method: 'get',
    params: query
  });
}

// 获取导入用户模板
export function coupon_stockUser_getTemplate_interface(query) {
  return request({
    url: '/cpm/merchant/coupon/stockUser/getTemplate',
    method: 'get',
    params: query
  });
}

// 获取页面二维码
export function coupon_stockH5_getShareInfo_interface(query) {
  return request({
    url: '/cpm/merchant/coupon/stock-h5/getShareInfo',
    method: 'get',
    params: query
  });
}

// h5活动开启关闭
export function stock_h5_updateWhetherOpen_interface(data) {
  return request({
    url: '/cpm/merchant/coupon/stock-h5/updateWhetherOpen',
    method: 'put',
    data: data
  });
}

/*
 *短信发券接口
 **/
// 短信发券列表
export function coupon_stock_sms_list_interface(data) {
  return request({
    url: '/cpm/merchant/coupon/stock-sms/page',
    method: 'post',
    data: data
  });
}

// 新建短信发券
export function coupon_stock_sms_create_interface(data) {
  return request({
    url: '/cpm/merchant/coupon/stock-sms/create',
    method: 'post',
    data: data
  });
}

// 短信发券活动查看
export function coupon_stock_sms_info_interface(query) {
  return request({
    url: '/cpm/merchant/coupon/stock-sms/info',
    method: 'get',
    params: query
  });
}

// 短信发券活动删除
export function coupon_stock_sms_delete_interface(query) {
  return request({
    url: '/cpm/merchant/coupon/stock-sms/delete',
    method: 'get',
    params: query
  });
}

// 短信发券,产看名单
export function coupon_stock_sms_infoUser_interface(data) {
  return request({
    url: '/cpm/merchant/coupon/stock-sms/info/users',
    method: 'post',
    data: data
  });
}

// 数据统计，券列表
export function coupon_getPage_interface(data) {
  return request({
    url: '/cpm/customer/coupon/getPage',
    method: 'post',
    data: data
  });
}

// 查询发送明细
export function coupon_flow_sendInfo_interface(data) {
  return request({
    url: '/cpm/customer/coupon/flow/sendInfo',
    method: 'get',
    params: data
  });
}

// 查询验证明细
export function coupon_flow_verifyInfo_interface(data) {
  return request({
    url: '/cpm/customer/coupon/flow/verifyInfo',
    method: 'get',
    params: data
  });
}

// 查询验证流水明细
export function coupon_flow_verifyFlowInfo_interface(data) {
  return request({
    url: '/cpm/customer/coupon/flow/verifyFlowInfo',
    method: 'post',
    data: data
  });
}

// 用户券详情
export function coupon_couponDetails_query_interface(query) {
  return request({
    url: "/cpm/customer/coupon/couponDetails/".concat(query),
    method: 'get'
    // params: query
  });
}

// 管理端延期用户券
export function coupon_postpone_interface(data) {
  return request({
    url: '/cpm/customer/coupon/postpone',
    method: 'put',
    data: data
  });
}

// 管理端批量延期用户券
export function coupon_batch_postpone_interface(data) {
  return request({
    url: '/cpm/customer/coupon/batch/postpone',
    method: 'put',
    data: data
  });
}

// 管理端作废用户券
export function coupon_invalidate_interface(data) {
  return request({
    url: "/cpm/customer/coupon/invalidate/".concat(data),
    method: 'put'
  });
}

// 管理端批量作废用户券
export function coupon_batch_invalidate_interface(data) {
  return request({
    url: "/cpm/customer/coupon/batch/invalidate",
    method: 'put',
    data: data
  });
}

// 抖音券新增
export function coupon_stockTrill_add_interface(data) {
  return request({
    url: '/cpm/merchant/coupon/stock-trill/add',
    method: 'post',
    data: data
  });
}

// 抖音券列表
export function coupon_stockTrill_page_interface(data) {
  return request({
    url: '/cpm/merchant/coupon/stock-trill/page',
    method: 'post',
    data: data
  });
}

// 抖音券详情
export function coupon_stockTrill_info_interface(query) {
  return request({
    url: "/cpm/merchant/coupon/stock-trill/info",
    method: 'get',
    params: query
  });
}

// 抖音修改券
export function coupon_stockTrill_update_interface(data) {
  return request({
    url: "/cpm/merchant/coupon/stock-trill/update",
    method: 'put',
    data: data
  });
}

// 抖音 免审核更新
export function coupon_stockTrill_auditFreeUpdate_interface(data) {
  return request({
    url: "/cpm/merchant/coupon/stock-trill/auditFreeUpdate",
    method: 'put',
    data: data
  });
}

// 抖音 券下架
export function coupon_stockTrill_offline_interface(data) {
  return request({
    url: "/cpm/merchant/coupon/stock-trill/offline",
    method: 'put',
    data: data
  });
}

// 抖音 券上架
export function coupon_stockTrill_online_interface(data) {
  return request({
    url: "/cpm/merchant/coupon/stock-trill/online",
    method: 'put',
    data: data
  });
}

// 查询商户渠道订单明细列表
export function channel_order_page_interface(data) {
  return request({
    url: '/oms/channel/order/page',
    method: 'post',
    data: data
  });
}
// 查询商户渠道订单详情
export function channel_order_detail_interface(query) {
  return request({
    url: "/oms/channel/order/detail/".concat(query),
    method: 'get'
  });
}

// 查询商户渠道订单日统计数据
export function channel_orderDay_page_interface(data) {
  return request({
    url: '/sta/mch/channel/order/day/page',
    method: 'post',
    data: data
  });
}

// 查询商户卡券(日)统计数据列表
export function coupon_day_pageInfo_interface(data) {
  return request({
    url: '/sta/merchant/coupon/day/pageInfo',
    method: 'post',
    data: data
  });
}

// 查询商户卡券(日)验证统计数据列表
export function coupon_verify_day_pageInfo_interface(data) {
  return request({
    url: '/sta/merchant/coupon/day/verify/pageInfo',
    method: 'post',
    data: data
  });
}

// 门店分组
export function shop_group_list_interface(query) {
  return request({
    url: "/crm/shop/group/list",
    method: 'get',
    params: query
  });
}

// 获取登录用户的可用渠道列表
export function sys_channel_list_interface(query) {
  return request({
    url: "/sys/channel/list",
    method: 'get',
    params: query
  });
}

// 获取登录用户的可用接入平台的渠道列表
export function sys_channel_joinList_interface(query) {
  return request({
    url: "/sys/channel/joinList",
    method: 'get',
    params: query
  });
}

// 获取登录用户的可用发券的渠道列表
export function sys_channel_createCoupon_List_interface(query) {
  return request({
    url: "/sys/channel/createCoupon/list",
    method: 'get',
    params: query
  });
}

// 抖音券列表v3
export function channel_coupon_stockPage_interface(data) {
  return request({
    url: '/cpm/merchant/channel/coupon/stock/page',
    method: 'post',
    data: data
  });
}
// 抖音券详情v3
export function channel_coupon_stockInfo_interface(data) {
  return request({
    url: '/cpm/merchant/channel/coupon/stock/info',
    method: 'get',
    params: data
  });
}

// 抖音券新增v3
export function channel_coupon_stockTrillAdd_interface(data) {
  return request({
    url: '/cpm/merchant/channel/coupon/stock/trill/add',
    method: 'post',
    data: data
  });
}

// 抖音券修改v3
export function channel_coupon_stockTrillUpdate_interface(data) {
  return request({
    url: "/cpm/merchant/channel/coupon/stock/trill/update",
    method: 'put',
    data: data
  });
}

// 下载列表
export function export_file_list_interface(data) {
  return request({
    url: '/sys/export/file/list',
    method: 'post',
    data: data
  });
}

// 导出渠道订单明细
export function export_fileOrder_details_interface(data) {
  return request({
    url: '/sys/export/file/order/details',
    method: 'post',
    data: data
  });
}

// 导出渠道订单统计
export function export_fileOrder_statistics_interface(data) {
  return request({
    url: '/sys/export/file/order/statistics',
    method: 'post',
    data: data
  });
}

// 导出商户卡券统计
export function export_fileCoupon_statistics_interface(data) {
  return request({
    url: '/sys/export/file/coupon/statistics',
    method: 'post',
    data: data
  });
}

// 导出商户卡券发送明细
export function export_fileCoupon_sendDetails_interface(data) {
  return request({
    url: '/sys/export/file/coupon/sendDetails',
    method: 'post',
    data: data
  });
}

// 导出商户卡券验证明细
export function export_fileCoupon_verifyDetails_interface(data) {
  return request({
    url: '/sys/export/file/coupon/verifyDetails',
    method: 'post',
    data: data
  });
}

// 导出商户卡券生成统计
export function export_fileCoupon_sendStatistics_interface(data) {
  return request({
    url: '/sys/export/file/coupon/send/statistics',
    method: 'post',
    data: data
  });
}

// 导出商户卡券验证统计
export function export_fileCoupon_verifyStatistics_interface(data) {
  return request({
    url: 'sys/export/file/coupon/verify/statistics',
    method: 'post',
    data: data
  });
}

// 券详情
export function coupon_info_interface(data) {
  return request({
    url: '/cpm/merchant/coupon/template/info/' + data,
    method: 'get'
  });
}

// 文件下载
export function sys_file_download_interface(exportId) {
  return request({
    url: "/sys/export/file/download/".concat(exportId),
    method: 'get'
    // params: query,
  });
}

// 上传图片
export function uploadImg_interface() {
  var upload = 'console/upload';
  if (process.env.NODE_ENV === 'development') {
    upload = 'api/upload';
  }
  return upload;
}
// 获取图片地址
export function getImgFile_interface(data) {
  var upload = "files/".concat(data);
  if (process.env.NODE_ENV === 'development') {
    upload = 'api/' + upload;
  } else {
    upload = "/console/" + upload;
  }
  return upload;
}

// xxxxx
export function uploadImg2_interface(data, url) {
  return request({
    url: url,
    method: 'post',
    data: data
  });
}
export default {
  stock_h5_list_interface: stock_h5_list_interface,
  stock_h5_add_interface: stock_h5_add_interface,
  stock_h5_info_interface: stock_h5_info_interface,
  stock_h5_update_interface: stock_h5_update_interface,
  coupon_stockUser_list_interface: coupon_stockUser_list_interface,
  coupon_stockUser_add_interface: coupon_stockUser_add_interface,
  coupon_stockUser_delete_interface: coupon_stockUser_delete_interface,
  coupon_pageCouponStockAddCouponTemplate_list_interface: coupon_pageCouponStockAddCouponTemplate_list_interface,
  coupon_stockUser_getTemplate_interface: coupon_stockUser_getTemplate_interface,
  coupon_stockH5_getShareInfo_interface: coupon_stockH5_getShareInfo_interface,
  stock_h5_updateWhetherOpen_interface: stock_h5_updateWhetherOpen_interface,
  // //
  coupon_stock_sms_list_interface: coupon_stock_sms_list_interface,
  coupon_stock_sms_create_interface: coupon_stock_sms_create_interface,
  coupon_stock_sms_info_interface: coupon_stock_sms_info_interface,
  coupon_stock_sms_delete_interface: coupon_stock_sms_delete_interface,
  coupon_stock_sms_infoUser_interface: coupon_stock_sms_infoUser_interface,
  coupon_getPage_interface: coupon_getPage_interface,
  coupon_flow_sendInfo_interface: coupon_flow_sendInfo_interface,
  uploadImg_interface: uploadImg_interface,
  getImgFile_interface: getImgFile_interface,
  coupon_flow_verifyInfo_interface: coupon_flow_verifyInfo_interface,
  coupon_couponDetails_query_interface: coupon_couponDetails_query_interface,
  coupon_postpone_interface: coupon_postpone_interface,
  coupon_batch_postpone_interface: coupon_batch_postpone_interface,
  coupon_invalidate_interface: coupon_invalidate_interface,
  coupon_batch_invalidate_interface: coupon_batch_invalidate_interface,
  coupon_stockTrill_add_interface: coupon_stockTrill_add_interface,
  coupon_stockTrill_page_interface: coupon_stockTrill_page_interface,
  coupon_stockTrill_info_interface: coupon_stockTrill_info_interface,
  coupon_stockTrill_update_interface: coupon_stockTrill_update_interface,
  coupon_stockTrill_auditFreeUpdate_interface: coupon_stockTrill_auditFreeUpdate_interface,
  coupon_stockTrill_offline_interface: coupon_stockTrill_offline_interface,
  coupon_stockTrill_online_interface: coupon_stockTrill_online_interface,
  uploadImg2_interface: uploadImg2_interface,
  channel_order_page_interface: channel_order_page_interface,
  channel_order_detail_interface: channel_order_detail_interface,
  channel_orderDay_page_interface: channel_orderDay_page_interface,
  coupon_day_pageInfo_interface: coupon_day_pageInfo_interface,
  coupon_verify_day_pageInfo_interface: coupon_verify_day_pageInfo_interface,
  sys_channel_list_interface: sys_channel_list_interface,
  sys_channel_joinList_interface: sys_channel_joinList_interface,
  sys_channel_createCoupon_List_interface: sys_channel_createCoupon_List_interface,
  coupon_flow_verifyFlowInfo_interface: coupon_flow_verifyFlowInfo_interface,
  export_file_list_interface: export_file_list_interface,
  export_fileOrder_details_interface: export_fileOrder_details_interface,
  export_fileOrder_statistics_interface: export_fileOrder_statistics_interface,
  export_fileCoupon_statistics_interface: export_fileCoupon_statistics_interface,
  export_fileCoupon_sendDetails_interface: export_fileCoupon_sendDetails_interface,
  export_fileCoupon_verifyDetails_interface: export_fileCoupon_verifyDetails_interface,
  export_fileCoupon_sendStatistics_interface: export_fileCoupon_sendStatistics_interface,
  export_fileCoupon_verifyStatistics_interface: export_fileCoupon_verifyStatistics_interface,
  sys_file_download_interface: sys_file_download_interface,
  channel_coupon_stockPage_interface: channel_coupon_stockPage_interface,
  channel_coupon_stockInfo_interface: channel_coupon_stockInfo_interface,
  channel_coupon_stockTrillAdd_interface: channel_coupon_stockTrillAdd_interface,
  channel_coupon_stockTrillUpdate_interface: channel_coupon_stockTrillUpdate_interface,
  coupon_info_interface: coupon_info_interface,
  coupon_resendMsg_interface: coupon_resendMsg_interface,
  coupon_stock_sms_resendMsg_interface: coupon_stock_sms_resendMsg_interface,
  shop_group_list_interface: shop_group_list_interface
};