//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'DialogComponent',
  props: {
    // 控制是否展示或隐藏对话框
    isShowDialog: {
      type: Boolean,
      default: false
    },
    // 父组件传过来的标题值
    title: {
      type: String,
      default: ''
    },
    // 是否显示关闭小图标
    showCloseIcon: {
      type: Boolean,
      default: true
    },
    // 是否开启背景遮罩层
    mask: {
      type: Boolean,
      default: true
    },
    // 是否点击遮罩层mask关闭弹出框
    clickMaskClose: {
      type: Boolean,
      default: false,
      require: true
    },
    height: {
      type: String,
      default: ''
    },
    width: {
      type: String,
      default: '500px'
    }
  },
  data: function data() {
    return {
      isShow: this.isShowDialog
    };
  },
  watch: {
    isShowDialog: function isShowDialog(val) {
      // console.log(val)
      this.isShow = val; // 新增isVisible的watch，监听变更并同步到IsShowPage上

      // 此处是挂载到body上
      // setTimeout(()=>{
      // 	const div = document.getElementById("lxj-dialog");
      // 	document.body.appendChild(div);
      // },1000)

      if (this.isShow) {
        document.getElementsByTagName('body')[0].className = 'noscall';
      } else {
        document.body.removeAttribute('class', 'noscall');
      }
    }
  },
  methods: {
    // 关闭弹出框
    close: function close() {
      this.$emit('beforeClose', false);
      this.$emit('update:isShowDialog', false);
      // this.isShowDialog = false;
      this.isShow = false;
    },
    // 点击遮罩层关闭弹框
    clickMaskCloseFn: function clickMaskCloseFn() {
      if (this.clickMaskClose == true) {
        this.$emit('beforeClose', false);
      } else {
        /* 这里要控制一下冒泡事件，注意第十行使用@click.stop
           不控制冒泡的话，点击内容区也会导致弹出框关闭*/

        return;
      }
    }
  }
};