//
//
//
//
//
//
//
//
//
//

import screenfull from "screenfull";
export default {
  name: "Screenfull",
  data: function data() {
    return {
      isFullscreen: false
    };
  },
  mounted: function mounted() {
    this.init();
  },
  beforeDestroy: function beforeDestroy() {
    this.destroy();
  },
  methods: {
    click: function click() {
      if (!screenfull.enabled) {
        this.$message({
          message: "you browser can not work",
          type: "warning"
        });
        return false;
      }
      screenfull.toggle();
    },
    change: function change() {
      this.isFullscreen = screenfull.isFullscreen;
    },
    init: function init() {
      if (screenfull.enabled) {
        screenfull.on("change", this.change);
      }
    },
    destroy: function destroy() {
      if (screenfull.enabled) {
        screenfull.off("change", this.change);
      }
    }
  }
};