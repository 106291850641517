//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Pagination from '@/components/Pagination';
import { validEmail, isMobile } from '@/utils/validate';
export default {
  name: 'QkAddUser',
  components: {
    Pagination: Pagination
  },
  filters: {},
  // props: ['hasArr', 'title','deptCode','ishow'],
  props: {
    ishow: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: '选择用户'
    },
    hasArr: {
      type: Array,
      default: function _default() {
        return [];
      }
    }
    // showLable:{
    // 	type:String,
    // 	default:'1'
    // },
  },
  data: function data() {
    return {
      dialogVisible: false,
      dialogTable1: [],
      total: 0,
      limit: 10,
      page: 1,
      loading: false,
      searchName: '',
      dialogVisible1: false,
      dialogVisible2: false,
      inputPhone: ''
    };
  },
  watch: {
    ishow: function ishow(val) {
      // console.log(val)
      this.dialogVisible = val; // 新增isVisible的watch，监听变更并同步到IsShowPage上
      if (this.dialogVisible) {
        // this.tabsActive = this.showLable
        // this.getshopList();
        // this.gogetList();
      }
    }
  },
  methods: {
    handleAddUser: function handleAddUser(type) {
      if (type == 'add') {
        this.dialogVisible1 = true;
      } else {
        this.dialogVisible2 = true;
      }
    },
    handleChange: function handleChange() {},
    gogetList: function gogetList() {
      var that = this;
      that.page = 1;
      that.limit = 10;
      switch (this.tabsActive) {
        case '1':
          that.getList1();
          break;
        case '2':
          that.getList2();
          break;
          break;
        default:
          break;
      }
    },
    // 优惠券列表
    getList1: function getList1() {
      var _this = this;
      var data = {
        page: this.page,
        limit: this.limit,
        // shopCode: this.shopCode,
        activityType: 1 // 优惠券
        // activityName: this.searchName
      };
      // if (this.searchTime) {
      // 	data.startTime = this.searchTime[0];
      // 	data.endTime = this.searchTime[1];
      // }
      if (this.shopCode) {
        data.shopCode = this.shopCode;
      }
      if (this.searchName) {
        data.activityName = this.searchName;
      }
      this.$getCommon(this.$codesKey.QKACTIVITY00001, data).then(function (res) {
        if (res.status == 0) {
          _this.dialogTable1 = res.data.records;
          _this.total = res.data.total;
        } else {
          _this.$message.error(res.message);
        }
      });
    },
    handleAddPhone: function handleAddPhone() {
      if (!isMobile(this.inputPhone)) return this.$message.error('请输入正确格式手机号！');
      this.dialogTable1.push({
        customerPhone: this.inputPhone
      });
      var obj = {};
      var arr = this.dialogTable1.reduce(function (cur, next) {
        obj[next.customerPhone] ? '' : obj[next.customerPhone] = true && cur.push(next);
        return cur;
      }, []); // 设置cur默认类型为数组，并且初始值为空的数组
      this.dialogTable1 = arr;
      this.inputPhone = '';
      this.closes1();
    },
    handleSubmit: function handleSubmit() {
      this.$emit('cb', this.dialogTable1);
      this.dialogVisible = false;
      this.closes();
    },
    beforeClose: function beforeClose(flag) {
      this.dialogVisible = flag;
      this.closes();
    },
    closes: function closes() {
      this.dialogTable1 = [];
      this.$emit('update:ishow', false);
      this.dialogVisible = false;
    },
    closes1: function closes1() {
      this.dialogVisible1 = false;
    }
  }
};