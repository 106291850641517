var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.dataForm.couponName
    ? _c("div", { staticClass: "couponOutBox" }, [
        _c(
          "div",
          {
            staticClass: "leftBox",
            class: _vm.dataForm.isActive ? "leftBox-a" : "",
          },
          [
            _c("div", { staticClass: "leftColor" }),
            _vm._v(" "),
            _c("div", { staticClass: "rightCon" }, [
              _vm.dataForm.couponType === 1
                ? _c("span", { staticClass: "outSpan" }, [
                    _c("span", { staticClass: "smallSpan" }, [_vm._v("¥")]),
                    _vm._v(" "),
                    _c("span", { staticClass: "bigSpan" }, [
                      _vm._v(_vm._s(_vm.dataForm.discountAmount)),
                    ]),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.dataForm.couponType === 2
                ? _c("span", { staticClass: "outSpan" }, [
                    _c("span", { staticClass: "bigSpan" }, [
                      _vm._v(_vm._s(_vm.dataForm.discountPercent / 10 || 0)),
                    ]),
                    _vm._v(" "),
                    _c("span", { staticClass: "smallSpan" }, [_vm._v("折")]),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.dataForm.couponType === 3
                ? _c("span", { staticClass: "outSpan" }, [
                    _c("span", { staticClass: "bigSpan" }, [_vm._v("提领券")]),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.dataForm.couponType === 6
                ? _c("span", { staticClass: "outSpan" }, [
                    _c("span", { staticClass: "smallSpan" }, [_vm._v("¥")]),
                    _vm._v(" "),
                    _c("span", { staticClass: "bigSpan" }, [
                      _vm._v(_vm._s(_vm.dataForm.discountAmount)),
                    ]),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.dataForm.couponType === 1
                ? _c("span", { staticClass: "typeBox" }, [_vm._v("代金券")])
                : _vm._e(),
              _vm._v(" "),
              _vm.dataForm.couponType === 2
                ? _c("span", { staticClass: "typeBox" }, [_vm._v("折扣券")])
                : _vm._e(),
              _vm._v(" "),
              _vm.dataForm.couponType === 6
                ? _c("span", { staticClass: "typeBox" }, [_vm._v("金额券")])
                : _vm._e(),
            ]),
          ]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "rightBox",
            class: _vm.dataForm.isActive ? "rightBox-a" : "",
          },
          [
            _c("div", { staticClass: "name" }, [
              _vm._v(_vm._s(_vm.dataForm.couponName)),
            ]),
            _vm._v(" "),
            _vm.dataForm.couponType == 1
              ? _c("div", { staticClass: "useCont" }, [
                  _vm._v(
                    "\n              · 满" +
                      _vm._s(_vm.dataForm.transactionMinimumAmount) +
                      "减" +
                      _vm._s(_vm.dataForm.discountAmount) +
                      "\n          "
                  ),
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm.dataForm.couponType == 2
              ? _c("div", { staticClass: "useCont" }, [
                  _vm._v(
                    "\n              · 满" +
                      _vm._s(_vm.dataForm.transactionMinimumAmount) +
                      "打" +
                      _vm._s(_vm.dataForm.discountPercent / 10 || 0) +
                      "折\n          "
                  ),
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm.dataForm.couponType == 3
              ? _c("div", { staticClass: "useCont" }, [
                  _vm._v(
                    "\n              · 满" +
                      _vm._s(_vm.dataForm.transactionMinimumAmount) +
                      "元可用\n          "
                  ),
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm.dataForm.couponType == 6
              ? _c("div", { staticClass: "useCont" }, [
                  _vm._v(
                    "\n              · 价值" +
                      _vm._s(_vm.dataForm.discountAmount) +
                      "元的金额券\n          "
                  ),
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm.dataForm.validityType == 1
              ? _c("div", { staticClass: "useTime" }, [
                  _vm._v(
                    "\n              · " +
                      _vm._s(_vm.dataForm.validityStartTime.split(" ")[0]) +
                      " --\n              " +
                      _vm._s(_vm.dataForm.validityEndTime.split(" ")[0]) +
                      "\n          "
                  ),
                ])
              : _c("div", { staticClass: "useTime" }, [
                  _vm._v(
                    "\n              · 领取后" +
                      _vm._s(_vm.dataForm.validityDays) +
                      "日内有效\n          "
                  ),
                ]),
          ]
        ),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }